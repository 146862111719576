import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import { Pagination } from "components/pagination"

import moment from "moment"
import PageLead from "../../components/pageLead";

const NewsPage = ({ data }) => {
  return (
    <Layout>
      <div className="news-page">
        <PageLead sub="NEWS"
                  title="ニュース"
        />
        <section className="contents-wrap">
          <ul className="news-list">
            {data.allStrapiOkulabHpPost.edges.map(({ node }) => (
                <li key={node.strapi_id}>
                  <Link to={`/news/${node.slug}`} className="news-item">
                      <p className="published-date">
                          {moment(node.publishedAt).format(`YYYY.MM.DD`)}
                      </p>
                      <p className="news-title">{node.title}</p>
                  </Link>
                </li>
            ))}
          </ul>
            <Pagination
                totalCount={data.allStrapiOkulabHpPost.pageInfo.totalCount}
                currentPage={data.allStrapiOkulabHpPost.pageInfo.currentPage}
                perPage={15}
                pageType="news"
            />
        </section>
      </div>
    </Layout>
  )
}
export const Head = () => (
  <Seo
    title="ニュース"
    description="OKULAB（オクラボ）のニュースリリースです。当社に関するお知らせやメディア掲載情報をご覧いただけます。"
  />
)
export default NewsPage

export const query = graphql`
  query ($limit: Int = 15, $skip: Int = 0) {
    allStrapiOkulabHpPost(
      sort: { fields: [publishedAt], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      pageInfo {
        totalCount
        currentPage
      }
      edges {
        node {
          slug
          strapi_id
          title
          publishedAt
        }
      }
    }
  }
`
